import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserContext from "./UserContext";
import React, {useContext} from "react";
import calcCrow from "../distance";

const Chat = ({chat}) => {
    const {t} = useTranslation("common");
    const user = useContext(UserContext);
    const distance = calcCrow(user.latitude, user.longitude, chat.latitude, chat.longitude);

    return <Link to={`/group/${chat.id}`} className={chat.joined ? 'chat joined' : 'chat'}>
        <h5>{chat.title}</h5>
        <b className="chat-distance">{Math.floor(distance)}</b> {t('km away')}<br/>
        {chat.members} 👥 <br/>
        {chat.messages} ✉️
    </Link>
}

export default Chat;
