import {Link} from "react-router-dom";
import {Navbar, Container, Nav} from 'react-bootstrap';
import {useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import UserContext from "./UserContext"
import SetUserContext from "./SetUserContext"
import {LanguageSelector} from "./LanguageSelector";

const Navigation = () => {
    const [expanded, setExpanded] = useState(false);
    const {t} = useTranslation("common");
    const user = useContext(UserContext);
    const setUser = useContext(SetUserContext);

    const handleLogout = () => {
        console.log('logout');
        let newUser = structuredClone(user);
        newUser.id = 0
        setUser(newUser)
        localStorage.setItem('currentUser', JSON.stringify(newUser));
    }

    return (
        <Navbar bg="light" collapseOnSelect expand="sm" expanded={expanded}>
            <Container>
                <Navbar.Brand as={Link} to="/">{t('Local Chats')}</Navbar.Brand>
                <LanguageSelector/>
                <Navbar.Toggle aria-controls="navbar-toggle" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="navbar-toggle">
                    <Nav className="me-auto">
                        {user.id !== 0 && <Nav.Link as={Link} to="/create" onClick={() => setExpanded(false)}>{t('New group')}</Nav.Link>}
                        {user.id !== 0 && <Nav.Link as={Link} to="/direct" onClick={() => setExpanded(false)}>{t('Direct')}</Nav.Link>}
                        {user.id !== 0 && <Nav.Link as={Link} to="/profile" onClick={() => setExpanded(false)}>{t('Profile')}</Nav.Link>}
                        {!user.id && <Nav.Link as={Link} to="/signup" onClick={() => setExpanded(false)}>{t('Sign up')}</Nav.Link>}
                        {!user.id && <Nav.Link as={Link} to="/login" onClick={() => setExpanded(false)}>{t('Login')}</Nav.Link>}
                        {user.id !== 0 && <Nav.Link onClick={handleLogout}>{t('Logout')}</Nav.Link>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
