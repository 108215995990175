import {useEffect, useState, useContext} from "react";
import Chat from "./Chat";
import useFetch from "../useFetch";
import {useTranslation} from "react-i18next";
import InfiniteScroll from 'react-infinite-scroller';
import * as Constants from "../constants";
import UserContext from "./UserContext";

const ChatList = () => {
    const [t] = useTranslation('common');
    const user = useContext(UserContext);
    const [joinedChats, setJoinedChats, isLoading2, error2] = useFetch("joined_groups");
    const [chats, setChats] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    const getChats = () => {
        const offset = chats.length;
        fetch(Constants.API_URL + `groups?offset=${offset}`, {
            headers: {'Content-Type': 'application/json', 'X-User-Id': user.id, 'X-Latitude': user.latitude, 'X-Longitude': user.longitude}
        })
            .then(response => {
                if (!response.ok) {
                    throw Error("Cannot fetch the resource");
                }
                return response.json();
            })
            .then(jsonData => {
                setHasMore(jsonData.length === 20)
                setChats([...chats,...jsonData]);
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getChats();
    }, []);

    return <div className="chats">
        { error2  && <div>{error2}</div> }
        { joinedChats?.length > 0 && <>{joinedChats.map((chat, key) => <Chat chat={chat} key={key}/>)}</>}
        <InfiniteScroll
            pageStart={0}
            loadMore={getChats}
            hasMore={hasMore}
            loader={<div className="loader" key={0}>{t('Loading ...')}</div>}
        >
            { chats?.length > 0 && <>{chats.map((chat, key) => <Chat chat={chat} key={key}/>)}</>}
        </InfiniteScroll>
    </div>
}

export default ChatList;
