import {Link, useParams} from "react-router-dom";
import useFetch from "../useFetch";
import Button from "react-bootstrap/Button";
import {useContext} from "react";
import UserContext from "../components/UserContext";
import calcCrow from "../distance";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async"

const Profile = () => {
    const [t] = useTranslation('common');
    const {id} = useParams()
    const currentUser = useContext(UserContext);
    const [user, setUser, isLoading, error] = useFetch("user/" + (id || currentUser.id));
    return <>
        <Helmet>
            <title>{user && user.username}</title>
            <meta
                name="description"
                content={t('description')}
            />
        </Helmet>
        { error && <div>{error}</div> }
        { isLoading && <div>Loading...</div> }
        { user && (
            <>
                <h1>{user.username} ({Math.floor(calcCrow(currentUser.latitude, currentUser.longitude, user.latitude, user.longitude))} {t('km away')})</h1>
                {
                    user.id === currentUser.id
                    ? <Button>{t('Edit')}</Button>
                    : <Button as={Link} to={'/direct/' + user.id}>{t('Send a message')}</Button>
                }
            </>
        )}
    </>
}

export default Profile;
