import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import UserContext from "./UserContext";
import calcCrow from "../distance";

const Contact = ({contact}) => {
    const [t] = useTranslation('common');
    const currentUser = useContext(UserContext);
    const distance = calcCrow(currentUser.latitude, currentUser.longitude, contact.latitude, contact.longitude);
    return <>
        <Link to={'/direct/' + contact.user_id}>
            <Card className="contact-card">
                <CardHeader><b>{contact.username}</b> (<b>{Math.floor(distance)}</b> {t('km away')}) {t('at')} {contact.last_sent_at}</CardHeader>
            </Card>
        </Link>
    </>
}

export default Contact;