import Card from "react-bootstrap/Card";
import CardBody from "react-bootstrap/CardBody";
import CardHeader from "react-bootstrap/CardHeader";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import UserContext from "./UserContext";
import calcCrow from "../distance";

const ChatMessage = ({message}) => {
    const [t] = useTranslation('common');
    const currentUser = useContext(UserContext);
    const distance = calcCrow(currentUser.latitude, currentUser.longitude, message.latitude, message.longitude);
    return <>
        <Card>
            <CardHeader><Link className="group-member-link" to={'/profile/' + message.sender_id}>{message.sender_name}</Link> (<b>{Math.floor(distance)}</b> {t('km away')}) {t('at')} {message.sent_at}</CardHeader>
            <CardBody>{message.message}</CardBody>
        </Card>
    </>
}

export default ChatMessage;