import {useContext, useEffect, useState, useRef} from "react";
import {Link, useParams} from "react-router-dom";
import useFetch from "../useFetch";
import SingleChatMessage from "../components/SingleChatMessage";
import SendMessageForm from "../components/SendMessageForm";
import InfiniteScroll from 'react-infinite-scroller';
import {useTranslation} from "react-i18next";
import * as Constants from "../constants";
import UserContext from "../components/UserContext";

function useStateAndRef(initial) {
    const [value, setValue] = useState(initial);
    const valueRef = useRef(value);
    valueRef.current = value;
    return [value, setValue, valueRef];
}

const OpenChat = () => {
    const [t] = useTranslation('common');
    const currentUser = useContext(UserContext);
    const {id} = useParams()
    const [user, setUser, isLoading1, error1] = useFetch("user/" + id);
    const [messages, setMessages, refLastMessages] = useStateAndRef([]);
    const [hasMore, setHasMore] = useState(false);

    const getOldMessages = () => {
        const firstMessage = refLastMessages.current[0] ? refLastMessages.current[refLastMessages.current.length - 1].id : 100000000;
        fetch(Constants.API_URL + `chat/${id}/messages?before=${firstMessage}`, {headers: {'X-User-Id': currentUser.id}})
            .then(response => {
                if (!response.ok) {
                    throw Error("Cannot fetch the resource");
                }
                return response.json();
            })
            .then(jsonData => {
                setHasMore(jsonData.length === 10)
                setMessages([...refLastMessages.current,...jsonData]);
            })
            .catch(err => console.log(err))
    }

    const getNewMessages = () => {
        const lastMessage = refLastMessages.current[0] ? refLastMessages.current[0].id : 0;
        fetch(Constants.API_URL + `chat/${id}/messages?after=${lastMessage}`, {headers: {'X-User-Id': currentUser.id}})
            .then(response => {
                if (!response.ok) {
                    throw Error("Cannot fetch the resource");
                }
                return response.json();
            })
            .then(jsonData => {
                setMessages([...jsonData, ...refLastMessages.current]);
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getOldMessages();
        const timerID = setInterval(() => getNewMessages(), 3000)
        return () => {
            clearInterval(timerID)
        }
    }, []);

    return (<>
        {(isLoading1) && <div>Loading...</div>}
        {error1 && <div>{error1}</div>}
        <h2><Link className="chat-title-link" to={'/profile/' + user.id}>{user.username}</Link></h2>
        <SendMessageForm type="chat" id={id} messages={messages} setMessages={setMessages}/>
        <InfiniteScroll
            pageStart={0}
            loadMore={getOldMessages}
            hasMore={hasMore}
            loader={<div className="loader" key={0}>{t('Loading ...')}</div>}
        >
            {messages.map((message, key) => <SingleChatMessage message={message} key={key}/>)}
        </InfiniteScroll>
    </>);
}

export default OpenChat;
