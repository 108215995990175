import {Link, useParams} from "react-router-dom";
import useFetch from "../useFetch";
import GroupMember from "../components/GroupMember";
import {useTranslation} from "react-i18next";
import {useContext, useEffect} from "react";
import UserContext from "../components/UserContext";
import calcCrow from "../distance";

const GroupChatInfo = () => {
    const [t] = useTranslation('common');
    const {id} = useParams()
    const [chat, setChats, isLoading1, error1] = useFetch(`group/${id}`);
    const [members, setMembers, isLoading2, error2] = useFetch(`group/${id}/members`);
    const currentUser = useContext(UserContext);

    members.map((user) => user.distance = calcCrow(currentUser.latitude, currentUser.longitude, user.latitude, user.longitude));
    members.sort((x, y) => x.distance < y.distance ? -1 : 1);

    useEffect(() => {
        members.map((user) => user.distance = calcCrow(currentUser.latitude, currentUser.longitude, user.latitude, user.longitude));
        members.sort((x, y) => x.distance < y.distance ? -1 : 1);
    }, [currentUser, members]);

    return (<>
        {(isLoading1 || isLoading2) && <div>Loading...</div>}
        {error1 && <div>{error1}</div>}
        {error2 && <div>{error2}</div>}
        {chat && <h2><Link className="chat-title-link" to={'/group/' + chat.id}>{chat.title}</Link> (<b>{chat.latitude ? Math.floor(calcCrow(currentUser.latitude, currentUser.longitude, chat.latitude, chat.longitude)) : 0}</b> {t('km away')})</h2>}
        {members && members.map((member, key) => <GroupMember member={member} key={key}/>)}
    </>);
}

export default GroupChatInfo;
