import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import UserContext from "../components/UserContext";
import * as Constants from "../constants";

const SendMessageForm = ({id, type, messages, setMessages}) => {
    const [message, setMessage] = useState('');
    const currentUser = useContext(UserContext);

    const handleSendMessage = (e) => {
        e.preventDefault();
        fetch(Constants.API_URL + type + '/' + id + '/message', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'X-User-Id': currentUser.id},
            body: JSON.stringify({'message': message})
        })
            .then(response => response.json())
            .then(jsonData => {
                if (jsonData.error) {
                    throw new Error(jsonData.error);
                }
                setMessages([jsonData, ...messages])
            })
            .catch(err => alert(err.message))

        // Clear the input field after sending the message
        setMessage('');
    };
    const [t] = useTranslation('common');
    return (
        <Form onSubmit={handleSendMessage}>
            <InputGroup className="mb-3">
                <Form.Control
                    type="text"
                    placeholder={t('Type your message...')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <Button variant="primary" onClick={handleSendMessage}>
                    {t('Send')}
                </Button>
            </InputGroup>
        </Form>
    );
}

export default SendMessageForm;