import Card from "react-bootstrap/Card";
import CardBody from "react-bootstrap/CardBody";
import CardHeader from "react-bootstrap/CardHeader";
import {useTranslation} from "react-i18next";

const SingleChatMessage = ({message}) => {
    const [t] = useTranslation('common');
    return <>
        <Card>
            <CardHeader>{t('at')} {message.sent_at}</CardHeader>
            <CardBody>{message.message}</CardBody>
        </Card>
    </>
}

export default SingleChatMessage;