import React from "react";
import ReactDOM from "react-dom/client"
import {HelmetProvider} from "react-helmet-async"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./main.css"
import App from "./components/App"
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_ru from "./translations/ru/common.json";
i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'ru',                              // language to use
    resources: {
        ru: {
            common: common_ru
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <HelmetProvider>
        <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
    </HelmetProvider>
)

