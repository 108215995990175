import useFetch from "../useFetch";
import Contact from "./Contact";
import {useTranslation} from "react-i18next";

const Direct = () => {
    const [contacts, setContacts, isLoading, error] = useFetch("chats");
    const [t] = useTranslation('common');
    return <>
        { error && <div>{error}</div> }
        { isLoading && <div>Loading...</div> }
        <h1>{t('Direct')}</h1>
        {contacts.map((contact, key) => <Contact contact={contact} key={key}/>)}
    </>
}

export default Direct;
