import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import UserContext from "../components/UserContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as Constants from "../constants";
import {useNavigate} from "react-router-dom";

const CreateGroupChat = () => {
    const [validated, setValidated] = useState(false);
    const [title, setTitle] = useState('');
    const [t] = useTranslation('common');
    const currentUser = useContext(UserContext);
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            fetch(Constants.API_URL + 'group', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'X-User-Id': currentUser.id, 'X-Latitude': currentUser.latitude, 'X-Longitude': currentUser.longitude},
                body: JSON.stringify({'group_name': title})
            })
                .then(response => {
                    if (!response.ok) {
                        throw Error('Error');
                    }
                    return response.json();
                })
                .then(jsonData => {
                    navigate(`/group/${jsonData.id}`);
                })
                .catch(err => console.log(err.message));
        }
    }
    return (<>
        <h1>{t('Create new group')}</h1>
        {currentUser && currentUser.id  && (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>{t('Group name')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('Type group title...')}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Button variant="primary" type="submit">
                            {t('Create')}
                        </Button>
                    </Form.Group>
                </Row>
            </Form>
        )}
    </>);
}

export default CreateGroupChat;
