import {useEffect, useState} from "react";
import * as Constants from './constants'
import UserContext from "./components/UserContext";
import {useContext} from "react";

const useFetch = (endpoint) => {
    const currentUser = useContext(UserContext);
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        const abortCont = new AbortController();
        fetch(Constants.API_URL + endpoint, {signal: abortCont.signal, headers: {'X-User-Id': currentUser.id, 'X-Latitude': currentUser.latitude, 'X-Longitude': currentUser.longitude}})
            .then(response => {
                if (!response.ok) {
                    throw Error("Cannot fetch the resource");
                }
                return response.json();
            })
            .then(jsonData => {
                setData(jsonData);
                setIsLoading(false);
                setError(null);
            })
            .catch(err => {
                if (err === "AbortError") {
                    console.log("aborted")
                } else {
                    setError(err.message)
                    setIsLoading(false);
                }
            })
        return () => abortCont.abort();
    }, [endpoint]);

    return [data, setData, isLoading, error];
}

export default useFetch;