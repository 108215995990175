import ChatList from "../components/ChatList";
import {Helmet} from "react-helmet-async"
import {useTranslation} from "react-i18next";

const Home = () => {
    const [t] = useTranslation('common');
    return <>
        <Helmet>
            <title>{t('Groups nearby')}</title>
            <meta
                name="description"
                content={t('description')}
            />
        </Helmet>
        <h1>{t('Groups nearby')}</h1>
        <ChatList/>
    </>
}

export default Home
