import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const NotFound = () => {
    const {t} = useTranslation("common");
    return (
        <div className="not-found">
            <h2>{t('Sorry')}</h2>
            <p>{t('That page cannot be found')}</p>
            <Link to="/">{t('Back to the chats')}</Link>
        </div>
    );
}

export default NotFound;
