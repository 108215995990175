import Card from "react-bootstrap/Card";
import CardBody from "react-bootstrap/CardBody";
import {Link} from "react-router-dom";
import {useContext} from "react";
import UserContext from "./UserContext";
import calcCrow from "../distance";
import {useTranslation} from "react-i18next";

const GroupMember = ({member}) => {
    const [t] = useTranslation('common');
    const currentUser = useContext(UserContext);
    const distance = calcCrow(currentUser.latitude, currentUser.longitude, member.latitude, member.longitude);
    return <>
        <Card>
            <CardBody><Link className="group-member-link" to={'/profile/' + member.id}>{member.username}</Link> (<b>{Math.floor(distance)}</b> {t('km away')})</CardBody>
        </Card>
    </>
}

export default GroupMember;