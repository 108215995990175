import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import UserContext from "../components/UserContext";
import SetUserContext from "../components/SetUserContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as Constants from "../constants";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async"

const SignUp = () => {
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [t] = useTranslation('common');
    const user = useContext(UserContext);
    const setUser = useContext(SetUserContext);
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === true && password === repeatPassword) {
            fetch(Constants.API_URL + 'signup', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({'username': name, 'password': password})
            })
                .then(response => response.json())
                .then(jsonData => {
                    if (jsonData.error) {
                        throw new Error(jsonData.error);
                    }

                    let newUser = structuredClone(user);
                    newUser.id = jsonData['id'];
                    newUser.username = jsonData['username'];
                    setUser(newUser)
                    localStorage.setItem('currentUser', JSON.stringify(newUser));
                    navigate('/');
                })
                .catch(err => alert(err.message))
        }
        setValidated(true);
    }
    return (<>
        <Helmet>
            <title>{t('Sign up')}</title>
            <meta
                name="description"
                content={t('description')}
            />
        </Helmet>
        <h1>{t('Sign up')}</h1>
        {!user.id && (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>{t('Login')}</Form.Label>
                        <Form.Control
                            required
                            pattern="[0-9A-Za-z_]{3,}"
                            type="text"
                            autoComplete="username"
                            placeholder={t('Login')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {t('login_error')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label>{t('Password')}</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            autoComplete="new_password"
                            minLength="8"
                            placeholder={t('Password')}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t('password_error')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                        <Form.Label>{t('Repeat password')}</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            autoComplete="new_password"
                            minLength="8"
                            placeholder={t('Repeat password')}
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t('repeat_password_error')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                        <Button variant="primary" type="submit">
                            {t('Sign up')}
                        </Button>
                    </Form.Group>
                </Row>
            </Form>
        )}
    </>);
}

export default SignUp;
