import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import UserContext from "../components/UserContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as Constants from "../constants";
import {useNavigate} from "react-router-dom";
import SetUserContext from "../components/SetUserContext";
import {Helmet} from "react-helmet-async"

const Login = () => {
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [t] = useTranslation('common');
    const user = useContext(UserContext);
    const setUser = useContext(SetUserContext);
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            fetch(Constants.API_URL + 'login', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'X-Latitude': user.latitude, 'X-Longitude': user.longitude},
                body: JSON.stringify({'username': name, 'password': password})
            })
                .then(response => response.json())
                .then(jsonData => {
                    if (jsonData.error) {
                        throw new Error(jsonData.error);
                    }

                    let newUser = structuredClone(user);
                    newUser.id = jsonData['id'];
                    newUser.username = jsonData['username'];
                    if (jsonData['latitude'] && jsonData['longitude']) {
                        newUser.latitude = jsonData['latitude'];
                        newUser.longitude = jsonData['longitude'];
                    }
                    setUser(newUser)
                    localStorage.setItem('currentUser', JSON.stringify(newUser));
                    navigate('/');
                })
                .catch(err => alert(err))
        }
        setValidated(true);
    }
    return (<>
        <Helmet>
            <title>{t('Login')}</title>
            <meta
                name="description"
                content={t('description')}
            />
        </Helmet>
        <h1>{t('Login')}</h1>
        {!user.id && (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>{t('Login')}</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            pattern="[0-9A-Za-z_]{3,}"
                            autoComplete="username"
                            placeholder={t('Login')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {t('login_error')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label>{t('Password')}</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            autoComplete="current_password"
                            placeholder={t('Password')}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t('password_error')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                        <Button variant="primary" type="submit">
                            {t('Login')}
                        </Button>
                    </Form.Group>
                </Row>
            </Form>
        )}
    </>);
}

export default Login;
