import {useContext, useEffect, useState, useRef} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import useFetch from "../useFetch";
import ChatMessage from "../components/ChatMessage";
import SendMessageForm from "../components/SendMessageForm";
import {useTranslation} from "react-i18next";
import UserContext from "../components/UserContext";
import calcCrow from "../distance";
import Button from "react-bootstrap/Button";
import {Helmet} from "react-helmet-async"
import * as Constants from "../constants";
import InfiniteScroll from 'react-infinite-scroller';

function useStateAndRef(initial) {
    const [value, setValue] = useState(initial);
    const valueRef = useRef(value);
    valueRef.current = value;
    return [value, setValue, valueRef];
}

const OpenGroupChat = () => {
    const navigate = useNavigate();
    const [t] = useTranslation('common');
    const {id} = useParams()
    const currentUser = useContext(UserContext);

    const [chat, setChat, isLoading1, error1] = useFetch(`group/${id}`);
    const [messages, setMessages, refLastMessages] = useStateAndRef([]);
    const [hasMore, setHasMore] = useState(false);

    const getOldMessages = () => {
        const firstMessage = refLastMessages.current[0] ? refLastMessages.current[refLastMessages.current.length - 1].id : 100000000;
        fetch(Constants.API_URL + `group/${id}/messages?before=${firstMessage}`)
            .then(response => {
                if (!response.ok) {
                    throw Error("Cannot fetch the resource");
                }
                return response.json();
            })
            .then(jsonData => {
                setHasMore(jsonData.length === 10)
                setMessages([...refLastMessages.current,...jsonData]);
            })
            .catch(err => console.log(err))
    }

    const getNewMessages = () => {
        const lastMessage = refLastMessages.current[0] ? refLastMessages.current[0].id : 0;
        fetch(Constants.API_URL + `group/${id}/messages?after=${lastMessage}`)
            .then(response => {
                if (!response.ok) {
                    throw Error("Cannot fetch the resource");
                }
                return response.json();
            })
            .then(jsonData => {
                setMessages([...jsonData, ...refLastMessages.current]);
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getOldMessages();
        const timerID = setInterval(() => getNewMessages(), 3000)
        return () => {
            clearInterval(timerID)
        }
    }, []);

    const handleDeleteGroup = () => {
        if (window.confirm(t('Are you sure to delete the group?'))) {
            fetch(Constants.API_URL + 'group/' + id, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json', 'X-User-Id': currentUser.id}
            })
                .then(response => response.json())
                .then(jsonData => {
                    if (jsonData.error) {
                        throw new Error(jsonData.error);
                    }
                    navigate('/');
                })
                .catch(err => alert(err.message))
        }
    }

    const handleUpdateLocation = () => {
        if (!currentUser.latitude || !currentUser.longitude) {
            return;
        }
        fetch(Constants.API_URL + 'group/' + id + '/location', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'X-User-Id': currentUser.id}
        })
            .then(response => response.json())
            .then(jsonData => {
                if (jsonData.error) {
                    throw new Error(jsonData.error);
                }
                const newChat = structuredClone(chat);
                newChat.latitude = currentUser.latitude;
                newChat.longitude = currentUser.longitude;
                setChat(newChat);
            })
            .catch(err => alert(err.message))
    }

    const handleJoinGroup = () => {
        fetch(Constants.API_URL + 'group/' + id + '/join', {headers: {'X-User-Id': currentUser.id}})
            .then(response => response.json())
            .then(jsonData => {
                if (jsonData.error) {
                    throw new Error(jsonData.error);
                }
                const newChat = structuredClone(chat);
                newChat.joined = true;
                setChat(newChat);
            })
            .catch(err => alert(err.message))
    }

    const handleLeaveGroup = () => {
        fetch(Constants.API_URL + 'group/' + id + '/leave', {headers: {'X-User-Id': currentUser.id}})
            .then(response => response.json())
            .then(jsonData => {
                if (jsonData.error) {
                    throw new Error(jsonData.error);
                }
                const newChat = structuredClone(chat);
                newChat.joined = false;
                setChat(newChat);
            })
            .catch(err => alert(err.message))
    }

    return (<>
        {(isLoading1) && <div>Loading...</div>}
        {error1 && <div>{error1}</div>}
        {chat && (
            <>
            <Helmet>
                <title>{chat.title}</title>
                <meta
                    name="description"
                    content={t('description')}
                />
            </Helmet>
            <h1>
                <Link className="chat-title-link" to={'/group/info/' + chat.id}>{chat.title}</Link> ({currentUser ? Math.floor(calcCrow(currentUser.latitude, currentUser.longitude, chat.latitude, chat.longitude)) : 0} {t('km away')})
            </h1>
            </>
        )}
        {chat && chat.joined && chat.owner_id === currentUser.id && <>
            <Button variant="danger" className="delete-group-btn" onClick={handleDeleteGroup}>
                {t('Delete')}
            </Button>
            <Button variant="success" className="delete-group-btn" onClick={handleUpdateLocation}>
                {t('Update location')}
            </Button>
        </>}
        {chat && chat.joined && chat.owner_id !== currentUser.id && <Button variant="danger" className="leave-group-btn" onClick={handleLeaveGroup}>
            {t('Leave')}
        </Button>}
        {currentUser.id !== 0 && chat && !chat.joined && (<>
            <p>{t('You need to be a member to send messages')}</p>
            <Button variant="success" className="leave-group-btn" onClick={handleJoinGroup}>
                {t('Join')}
            </Button>
        </>)}
        {!currentUser?.id && (<>
        <p>{t('You need to login to send messages')}</p>
            <Button variant="success" className="leave-group-btn" onClick={() => navigate('/signup')}>
                {t('Sign up')}
            </Button>
            <Button variant="success" className="leave-group-btn" onClick={() => navigate('/login')}>
                {t('Login')}
            </Button>
        </>)}
        {chat && chat.joined && <SendMessageForm type="group" id={chat.id} messages={messages} setMessages={setMessages}/>}

        <InfiniteScroll
            pageStart={0}
            loadMore={getOldMessages}
            hasMore={hasMore}
            loader={<div className="loader" key={0}>{t('Loading ...')}</div>}
        >
            {messages && messages.map((message, key) => <ChatMessage message={message} key={key}/>)}
        </InfiniteScroll>
    </>);
}

export default OpenGroupChat;
