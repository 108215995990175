import Navigation from './Navigation'
import Home from '../pages/Home'
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ReactGA from 'react-ga';
import Direct from "./Direct";
import Profile from "../pages/Profile";
import OpenGroupChat from "../pages/OpenGroupChat";
import Container from "react-bootstrap/Container";
import GroupChatInfo from "../pages/GroupChatInfo";
import OpenChat from "../pages/OpenChat";
import CopyrightBlock from "./Copyright";
import React, {useEffect, useState} from "react";
import UserContext from "./UserContext";
import SetUserContext from "./SetUserContext";
import NotFound from "../pages/NotFound";
import * as Constants from '../constants'
import CreateGroupChat from "../pages/CreateGroupChat";
import SignUp from "../pages/SignUp";
import Login from "../pages/Login";

const App = () => {
    const TRACKING_ID = "G-XYFMH8MLWD";
    ReactGA.initialize(TRACKING_ID);

    let defaultUser = localStorage.getItem('currentUser');
    if (defaultUser) {
        try {
            defaultUser = JSON.parse(defaultUser);
        } catch (e) {
            defaultUser = null;
        }
    }
    if (!defaultUser) {
        defaultUser = {
            "id": 0,
            "username": "",
            "latitude": 0.0,
            "longitude": 0.0,
            "has_location": false,
            "last_location": 0,
        };
    }
    const [user, setUser] = useState(defaultUser);

    useEffect(() => {
        const successCallback = (position) => {
            console.log(position.coords);
            if (user.latitude !== position.coords.latitude || user.longitude !== position.coords.longitude || !user.has_location) {
                let newUser = structuredClone(user)
                newUser.latitude = position.coords.latitude;
                newUser.longitude = position.coords.longitude;
                newUser.has_location = true;
                newUser.last_location = new Date().getTime();
                setUser(newUser)
                localStorage.setItem('currentUser', JSON.stringify(newUser));

                fetch(Constants.API_URL + 'user/location/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-User-Id': newUser.id,
                        'X-Latitude': newUser.latitude,
                        'X-Longitude': newUser.longitude
                    }
                })
                    .then(response => response.json())
                    .then(jsonData => console.log(jsonData))
                    .catch(err => console.log(err.message))
            }
        };

        const errorCallback = (error) => {
            if (new Date().getTime() - user.last_location < 60000) {
                console.log('Skip location');
                return;
            }

            let errorMessage;
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    errorMessage = "User denied the request for Geolocation."
                    break;
                case error.POSITION_UNAVAILABLE:
                    errorMessage = "Location information is unavailable."
                    break;
                case error.TIMEOUT:
                    errorMessage = "The request to get user location timed out."
                    break;
                default:
                    errorMessage = "An unknown error occurred."
                    break;
            }
            console.log(errorMessage);
            console.log('request fake locations');
            fetch(Constants.API_URL + 'user/location/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-User-Id': user.id,
                    'X-Latitude': user.latitude,
                    'X-Longitude': user.longitude
                }
            })
                .then(response => response.json())
                .then(jsonData => {
                    console.log(jsonData);
                    if (user.latitude !== jsonData.latitude || user.longitude !== jsonData.longitude || !user.has_location) {
                        let newUser = structuredClone(user);
                        newUser.latitude = jsonData.latitude;
                        newUser.longitude = jsonData.longitude;
                        newUser.has_location = true;
                        newUser.last_location = new Date().getTime();
                        setUser(newUser);
                        localStorage.setItem('currentUser', JSON.stringify(newUser));
                    }
                })
                .catch(err => console.log(err.message))
        };

        navigator.geolocation.watchPosition(successCallback, errorCallback);
    }, [user])

    return (
        <Router>
            <Container>
                <SetUserContext.Provider value={setUser}>
                    <UserContext.Provider value={user}>
                        <header><Navigation/></header>
                        <main>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/signup" element={<SignUp/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/create" element={<CreateGroupChat/>}/>
                                <Route path="/group/:id" element={<OpenGroupChat/>}/>
                                <Route path="/group/info/:id" element={<GroupChatInfo/>}/>
                                <Route path="/direct/:id" element={<OpenChat/>}/>
                                <Route path="/direct" element={<Direct/>}/>
                                <Route path="/profile" element={<Profile/>}/>
                                <Route path="/profile/:id" element={<Profile/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                        </main>
                        <footer>
                            <CopyrightBlock/>
                        </footer>
                    </UserContext.Provider>
                </SetUserContext.Provider>
            </Container>
        </Router>
    );
}

export default App