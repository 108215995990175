import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export const LanguageSelector = () =>
{
    const [t, i18n] = useTranslation('common');
    const language = localStorage.getItem('language');

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    }

    useEffect(() => {
        changeLanguage(language || 'en');
    }, []);

    return <div>
        <span className="lang-button" onClick={() => changeLanguage('en')}>🇬🇧</span>
        &nbsp;
        <span className="lang-button" onClick={() => changeLanguage('ru')}>🇷🇺</span>
    </div>
}
